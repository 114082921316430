import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import IntegrationInfo from '@/modules/common/components/mixins/integrationInfo'

@Component({
  name: 'GtrSyncsView',
  filters: {
    lowercase (value) {
      return value.toLowerCase()
    }
  },
  computed: {
    ...mapState('syncs', ['syncs', 'active_syncs'])
  }
})

export default class GtrSyncsView extends mixins(GtrSuper, IntegrationInfo) {
  data () {
    return {
      activeIntegrations: []
    }
  }

  updated () {
    this.$emit('update:layout', GtrModuleLayout)
  }

  getLogo (integration) {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type || this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type.slice(0, integration.type.length - 4)) {
        return this.$data.integrationCardInfo[i].logo
      }
    }
  }

  getName (integration) {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type || this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type.slice(0, integration.type.length - 4)) {
        return this.$data.integrationCardInfo[i].display
      }
    }
  }

  getAlt (integration) {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type || this.$data.integrationCardInfo[i].name.toLowerCase() === integration.type.slice(0, integration.type.length - 4)) {
        return this.$data.integrationCardInfo[i].alt
      }
    }
  }

  async mounted () {
    await this.$store.dispatch('syncs/getActiveSyncs', this.$route.params.event_uuid)
    this.$store.dispatch('common/hideLoader')
  }

  // push vuex binding into an array within data
  @Watch('active_syncs')
  onActiveSyncChange (payload: any) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        this.$data.activeIntegrations.push(payload[i])
      }
    }
  }
}
